<template>
  <div class="competence">
    <Header title="产品列表" index="首页" titleOne="政策管理" beforeTitle="产品列表" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-select v-model="productParams.type" placeholder="请选择产品类型" @change="getProductType">
            <el-option label="全部" value="0"></el-option>
            <el-option
              v-for="item in productType"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select v-model="productParams.province_id" placeholder="请选择省" @change="getcity">
            <el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="productParams.city_id" placeholder="请选择市" @change="gettown">
            <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="productParams.town_id" placeholder="请选择区">
            <el-option v-for="item in town" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-input
            v-model="productParams.keywords"
            placeholder="请输入名称关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
        <el-button class="add" @click="addProduct('')">新增</el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="product.data"
        style="width: 100%"
      >
        <el-table-column prop="product_name" label="产品类型" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.product_name ? scope.row.product_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="title" label="产品名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.title ? scope.row.title : "-" }}</template>
        </el-table-column>
        <el-table-column prop="area" label="所属区域" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.area ? scope.row.area : "-" }}</template>
        </el-table-column>
        <el-table-column prop="url" label="产品依据" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.url ? scope.row.url : "-" }}</template>
        </el-table-column>
        <el-table-column prop="description" label="概要描述" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.description ? scope.row.description : "-" }}</template>
        </el-table-column>
        <el-table-column prop="department" label="主管部门" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.department ? scope.row.department : "-" }}</template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="370px">
          <template slot-scope="scope">
            <el-button
              class="edit"
              size="mini"
              @click="addProduct(scope.row.id)"
              v-if="product.option_rule.edit_detail == true"
            >编辑</el-button>
            <!-- <el-button
              class="edit"
              size="mini"
              @click="handleCheck(scope.row.id)"
              v-if="product.option_rule.view == true"
            >指南</el-button>-->
            <el-button
              class="edit"
              size="mini"
              @click="handleRule(scope.row.id)"
              v-if="product.option_rule.edit_rule == true"
            >政策规则</el-button>

            <el-upload
              class="upload-demo"
              ref="imgupload"
              action="string"
              :name="'' + scope.row.id"
              :http-request="Uploadfile"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :file-list="fileList"
            >
              <el-button
                type="primary"
                class="fileUpload edit"
                v-if="
                  scope.row.upload_status == 0 &&
                  product.option_rule.upload == true
                "
              >上传模板</el-button>
            </el-upload>
            <el-upload
              class="upload-demo"
              ref="imgupload"
              action="string"
              :name="'' + scope.row.id"
              :http-request="Uploadfile"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :file-list="fileList"
            >
              <el-button
                type="primary"
                class="fileUpload"
                v-if="
                  scope.row.upload_status == 1 &&
                  product.option_rule.upload == true
                "
              >重新上传</el-button>
            </el-upload>
            <el-button
              style="margin-left:10px"
              type="primary"
              class="del"
              size="mini"
              @click="handleDelete(scope.row.id)"
              v-if="product.option_rule.delete == true"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="product.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="productParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="productParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="product.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      productType: [],
      opt: { id: "0", name: "全部" },
      loading: true,
      productParams: {
        page: 1,
        limit: 10,
        keywords: "",
        province_id: "",
        city_id: "",
        town_id: "",
        order: "id desc",
        type: '',
      },
      city: [],
      town: [],
      province: [],
      options: [],
      fileList: [],
      value: "",
      input: "",
    };
  },
  activated () {
    // this.handleSearch();
    this.productParams
    this.$store.dispatch("getProductList", this.productParams);
    // console.log(this.productParams, '88')
  },
  computed: {
    ...mapState(["product"]),
  },
  mounted () {
    this.getprovince();
  },
  created () {
    this.getProductType()
  },
  methods: {
    // 获取产品类型
    getProductType () {
      this.axios.get("/api/policy/select_product_type").then((res) => {
        this.productType = res.data;
      });
      this.$store.dispatch("getProductList", this.productParams);
    },
    //获取产品列表
    getProductList () {
      this.axios.get('/api/policy/product_list', { params: { ...this.productParams } }).then((res) => {
        this.product.data = res.data.data
      })
    },
    handleSizeChange (val) {
      this.productParams.limit = val;
      this.$store.dispatch("getProductList", this.productParams);
    },
    handleCurrentChange (val) {
      this.productParams.page = val;
      this.$store.dispatch("getProductList", { ...this.productParams });
    },
    addProduct (id) {
      this.$router.push("/product/edit?id=" + id);
    },
    handleCheck (id) {
      this.$router.push("/product/guide?id=" + id);
    },
    handleRule (id) {
      this.$router.push("/product/Policyrules?id=" + id);
    },
    Uploadfile (param) {
      this.loading = true
      let file = param.file;
      let id = param.filename;
      const form = new FormData();
      form.append("file", file);
      form.append("id", id);
      this.upload("/api/policy/upload_module", form).then((res) => {
        setTimeout(() => {
          this.loading = false
        }, 5000)
        this.$message({
          type: 'success',
          message: res.message
        })
        // this.getProductList()
        this.reload();
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
    handleSearch () {
      this.$store.dispatch("getProductList", { ...this.productParams, page: '', limit: '' });
      this.productParams.page = 1
    },
    getprovince () {
      this.axios.get("/api/region/getProvince").then((res) => {
        this.province = res.data;
        this.province.unshift(this.opt);
        this.$store.dispatch("getProductList", this.productParams)
      });
    },
    getcity (id) {
      this.axios
        .get("/api/region/getCity", {
          params: {
            province_id: id,
          },
        })
        .then((res) => {
          this.city = res.data;
          this.$store.dispatch("getProductList", this.productParams);
        });
      if (this.productParams.province_id == "0") {
        this.productParams.city_id = "";
        this.productParams.town_id = "";
      }
    },
    gettown (id) {
      this.axios
        .get("/api/region/getTown", {
          params: {
            city_id: id,
          },
        })
        .then((res) => {
          this.town = res.data;
          this.$store.dispatch("getProductList", this.productParams);
        });
    },
    getType () {
      this.$store.dispatch("getProductList", this.productParams)
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/policy/delete_product", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getProductList()
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>

<style>
.upload-demo {
  display: inline-block;
}
.fileUpload {
  background: #f2f3f9 !important;
  border-radius: 2px;
  color: #0f38ff !important;
  border: none;
  font-weight: bold;
  padding: 7px 15px;
  font-size: 12px;
  margin: 0 10px;
}
</style>
